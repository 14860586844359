import {computed} from "vue";
import {useStore} from "vuex";

export default () => {
    const store = useStore()
    const platform = computed(()=>{
        return store.state.current_platform.general
    })

    return {
        platform,
    }
}