<template>
  <ion-page>
    <ion-content :fullscreen="true" class="bg-lighter ion-content">
      <vertical-nav-menu></vertical-nav-menu>
      <div class="nk-wrap">
        <nav-bar></nav-bar>
        <main-block>
          <block-content>
            <row class="g-gs">
              <column default="12" :lg="3">
                <card>
                  <card-header title="Calendars" class="mb-2"></card-header>
                  <div class="custom-control custom-control-sm custom-checkbox d-block">
                    <input type="checkbox" class="custom-control-input" id="l_calendar_check" value="leads" v-model="calendars">
                    <label class="custom-control-label label-primary" for="l_calendar_check">Leads</label>
                  </div>
                  <div class="custom-control custom-control-sm custom-checkbox d-block">
                    <input type="checkbox" class="custom-control-input" id="ap_calendar_check" value="appointments" v-model="calendars">
                    <label class="custom-control-label label-success" for="ap_calendar_check">Appointments</label>
                  </div>
                  <div class="custom-control custom-control-sm custom-checkbox d-block">
                    <input type="checkbox" class="custom-control-input" id="ot_calendar_check" value="other" v-model="calendars">
                    <label class="custom-control-label label-dark" for="ot_calendar_check">Other</label>
                  </div>
                  <div v-if="!googleCalendarStatus" class="mt-2">
                    <a href="javascript:;" v-on:click="connectGoogleCalendar">Connect google calendar</a>
                  </div>
                  <h6 class="title mb-2 mt-4">Availability</h6>
                  <div v-for="(x,index) in availability" :key="index">
                    <row class="mt-1">
                      <column default="4">
                        <div class="custom-control custom-control-sm custom-checkbox">
                          <input type="checkbox" class="custom-control-input" v-bind:id="'c_ava_'+x.day" v-model="x.selected">
                          <label class="custom-control-label" v-bind:for="'c_ava_'+x.day">{{ x.name }}</label>
                        </div>
                      </column>
                      <column default="4" class="px-1" v-if="x.selected" v-bind:id="'w_st_'+index">
                        <ion-select :value="x.start_time" v-model="x.start_time" interface="popover" class="py-0 px-1">
                          <ion-select-option v-for="time in startEndTimeList()" :value="time.key" :key="time.value">{{ time.value }}</ion-select-option>
                        </ion-select>
                      </column>
                      <column default="4" class="px-1" v-if="x.selected && x.start_time.length > 0">
                        <ion-select :value="x.end_time" v-model="x.end_time" interface="popover" class="py-0 px-1" v-bind:id="'w_et_'+index">
                          <ion-select-option v-for="time in startEndTimeList(x.start_time)" :value="time.key" :key="time.value">{{ time.value }}</ion-select-option>
                        </ion-select>
                      </column>
                    </row>
                  </div>
                  <div class="mt-3">
                    <ion-spinner v-if="availabilitySpinner"></ion-spinner>
                    <nk-button v-else type="primary" size="sm" v-on:click="updateAvailability">Update</nk-button>
                  </div>
                </card>
              </column>
              <column default="12" :lg="9">
                <card>
                  <FullCalendar ref="calendarRef" :options="calendarOptions"></FullCalendar>
                </card>
              </column>
            </row>

            <ion-modal
                :is-open="isModalOpenRef"
                css-class="my-custom-class"
                @didDismiss="setModalOpenStatus(false)">
              <i-modal :title="openedEvent.title" @modal-close="setModalOpenStatus(false)" :toolbar-color="openedEvent.extendedProps.color">
                <calendar-event-view :start_time="openedEvent.start" :end_time="openedEvent.end" :description="openedEvent.extendedProps.description"></calendar-event-view>
                <div class="d-flex mt-5 justify-content-between">
                  <nk-button type="primary" v-if="openedEvent.extendedProps.editable" v-on:click="setEventAddEditModalOpenStatus(true)">Edit event</nk-button>
                  <nk-button type="danger" dim v-if="openedEvent.extendedProps.deletable" v-on:click="deleteEvent()">Delete</nk-button>
                </div>
              </i-modal>
            </ion-modal>

            <ion-modal
                :is-open="eventAddEditModalOpenRef"
                css-class="my-custom-class"
                @didDismiss="setEventAddEditModalOpenStatus(false)">
              <i-modal
                  :title="(openedEvent.defId === '' ? 'Add' : 'Update') + ' Event'"
                  @modal-close="setEventAddEditModalOpenStatus(false)"
                  :toolbar-color="openedEvent.extendedProps.color">
                <calendar-event-form
                    :title="openedEvent.title"
                    :start-time="openedEvent.start"
                    :end-time="openedEvent.end"
                    :description="openedEvent.extendedProps.description" @event-data-updated="eventDataUpdated">

                </calendar-event-form>
                <div class="d-flex mt-5 justify-content-between">
                  <nk-button
                      type="primary"
                      v-on:click="updateEvent"
                      v-if="!eventUpdateSpinner">{{ (openedEvent.defId === '' ? 'Add' : 'Update') + ' Event' }}</nk-button>
                  <nk-button
                      type="danger" dim
                      v-on:click="setEventAddEditModalOpenStatus(false)"
                      v-if="!eventUpdateSpinner">Discard</nk-button>
                  <ion-spinner v-if="eventUpdateSpinner"></ion-spinner>
                </div>
              </i-modal>
            </ion-modal>

          </block-content>
        </main-block>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
//import "@fullcalendar/core/vdom"

import {IonContent, IonPage, loadingController, toastController, /*IonHeader, IonTitle, IonToolbar*/} from '@ionic/vue';
import {defineComponent, onMounted, reactive, ref, watch} from 'vue';
//import axios from "@/libs/axios";
//import card from "@/layouts/components/card";
import VerticalNavMenu from "@/@core/layouts/vertical-nav-menu/VerticalNavMenu";
import NavBar from "@core/layouts/app-nav-bar/NavBar";
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import { Row, Column } from '@core/layouts';
import { Card } from '@core/components/cards'

import axios from "@/libs/axios"

import $ from "jquery"
import "bootstrap"

import FullCalendar from "@fullcalendar/vue3"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import listGridPlugin from "@fullcalendar/list"
import interactionPlugin from "@fullcalendar/interaction"

import { IonModal, IonSelect, IonSelectOption, IonSpinner, alertController,} from '@ionic/vue';
import IModal from "@core/components/ion-modal/IModal";
import CalendarEventView from "@/views/calendar/components/CalendarEventView";
import CalendarEventForm from "@/views/calendar/components/CalendarEventForm";
import CardHeader from "@core/components/cards/CardHeader";
import NkButton from "@core/components/button/NkButton";

export default defineComponent({
  name: 'Home',
  components: {
    NkButton,
    CardHeader,
    CalendarEventForm,
    CalendarEventView,
    //NkButton,
    IModal,
    IonModal,
    FullCalendar,

    Card,
    Row,
    Column,
    BlockContent,
    MainBlock,
    NavBar,
    VerticalNavMenu,
    IonContent,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonSpinner,
  },
  setup() {

    const calendarRef = ref(null)
    let eventUpdateSpinner = ref(false)

    // Is user google calendar authenticated or not
    let googleCalendarStatus = ref(false)

    // Connect google calendar
    const connectGoogleCalendar = () => {

      alertController.create({
        header: 'Connect Google Calendar',
        message: 'By connecting Google calendar, you will be able to receive followup and others events in your google calendar.',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            /*handler: blah => {
              console.log('Confirm Cancel:', blah)
            },*/
          },
          {
            text: 'Connect',
            handler: () => {
              axios.post('/api/advisor/calendar/connect_external', {source: 'google'})
                  .then(resp => {
                    if(resp.data.success){
                      window.open(resp.data.data.auth_url, '_blank').focus();
                    }
                  })
                  .catch()
            },
          },
        ],
      }).then( (alert) => alert.present())
    }

    const isModalOpenRef = ref(false);
    const setModalOpenStatus = (state) => isModalOpenRef.value = state;

    const eventAddEditModalOpenRef = ref(false)
    const setEventAddEditModalOpenStatus = (state) => eventAddEditModalOpenRef.value = state;

    let calendars = ref(['leads', 'appointments', 'other'])

    let openedEvent = reactive({
      backgroundColor: '#1c2b46',
      defId: '',
      title: '',
      extendedProps:{
        color: 'dark',
        description: '',
        deletable: true,
        editable: false,
        event_id: '',
        calendar: 'other',
      },
      start: '',
      end: '',
    })
    let openedEventObj = reactive({obj: ''})

    let events = ref([])
    let filteredEvents  = ref([])
    let calendarOptions = reactive({
      plugins: [dayGridPlugin, timeGridPlugin, listGridPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      weekends: true,
      height: 800,
      contentHeight: 780,
      aspectRatio: 3,
      headerToolbar: {
        left: 'title prev,next',
        center: null,
        right: 'today dayGridMonth,timeGridWeek,timeGridDay,listWeek'
      },
      bootstrapFontAwesome: false,
      themeSystem: 'bootstrap',
      //rerenderDelay: 10000,
      eventMouseEnter: function(info) {
        $(info.el).popover({
          template: '<div class="popover"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
          title: info.event._def.title,
          content: info.event._def.extendedProps.description,
          placement: 'top',
        });
        info.event._def.extendedProps.description ? $(info.el).popover('show') : $(info.el).popover('hide');
      },
      eventMouseLeave: function(info) {
        $(info.el).popover('hide');
      },
      eventClick: function(info) {

        openedEventObj.obj = info.event
        openedEvent.start  = info.event.startStr
        openedEvent.end    = info.event.endStr
        for(let x in  info.event._def){
          if(x === 'extendedProps'){
            for(let y in info.event._def[x]){
              openedEvent[x][y] = info.event._def[x][y]
            }
          }
          else{
            openedEvent[x] = info.event._def[x]
          }
        }
        setModalOpenStatus(true)
        $('.popover').popover('hide');
      },
      dateClick: function(d) {
        openedEvent.defId = ''
        openedEvent.title = ''
        openedEvent.start = new Date(d.dateStr).toISOString()
        openedEvent.end   = new Date(d.dateStr+' 23:00:00').toISOString()
        openedEvent.extendedProps.description = ''
        openedEvent.extendedProps.editable = true
        openedEvent.extendedProps.calendar = 'other'
        openedEvent.extendedProps.color    = 'dark'
        setEventAddEditModalOpenStatus(true)
      },
      events: filteredEvents,
      eventDidMount: function (info){
        return calendars.value.includes(info.event._def.extendedProps.calendar)
      },
    })

    let availability = reactive([
      {day:0, name: 'Sun', start_time: '', end_time: '', selected: false},
      {day:1, name: 'Mon', start_time: '', end_time: '', selected: false},
      {day:2, name: 'Tue', start_time: '', end_time: '', selected: false},
      {day:3, name: 'Wed', start_time: '', end_time: '', selected: false},
      {day:4, name: 'Thu', start_time: '', end_time: '', selected: false},
      {day:5, name: 'Fri', start_time: '', end_time: '', selected: false},
      {day:6, name: 'Sat', start_time: '', end_time: '', selected: false}
    ])

    const getCalendars = () => {
      axios.post('/api/advisor/calendar', {calendar: 'leads'})
      .then(resp => {
        resp = resp.data
        if(!resp.success){
          console.log('Calendar events error: '+resp.message)
        }
        else{
          googleCalendarStatus.value = resp.data.google_calendar_auth
          for(let x in resp.data.events){
            events.value.push( resp.data.events[x])
          }
          for(let x in availability){
            for(let y in resp.data.availability){
              if(availability[x].day === parseInt( resp.data.availability[y].week_day)){
                availability[x].selected = true
                availability[x].start_time = resp.data.availability[y].start_time
                availability[x].end_time = resp.data.availability[y].end_time
              }
            }
          }
          filterEvents()
        }
      })
      .catch()
      .then()
    }

    const filterEvents = () => {
      filteredEvents.value = []
      for(let x in events.value){
        if(calendars.value.includes( events.value[x].extendedProps.calendar)){
          filteredEvents.value.push(events.value[x])
        }
      }
    }

    const startEndTimeList = ( start_time ) => {

      let data = []
      for(let i = 0; i < 24; i++){
        let j = 0
        while (j < 60 ){

          let i_r = (i < 10) ? (0+''+i) : i
          let j_r = (j < 10) ? (0+''+j) : j

          if(undefined !== start_time){
            let st = parseInt(start_time.replace(':', ''))
            if( parseInt(i_r+''+j_r) < st){
              j = j+15
              continue
            }
          }

          let am_pm = ( i < 12) ? 'am' : 'pm'
          let time_24 = i_r+':'+j_r
          let time_12 = ( i > 12) ? (i-12) : ( ( i == 0) ? 12 : i )
          time_12 += ':' + j_r +am_pm
          data.push({key: time_24, value: time_12})

          j = j+15
        }
      }
      return data
    }

    let availabilitySpinner = ref(false)
    const updateAvailability = () => {

      availabilitySpinner.value = true
      let ajData = []
      for(let x in availability){
        if(availability[x].selected){
          ajData.push(availability[x])
        }
      }
      axios.post('/api/advisor/availability/update', {availability: JSON.stringify(ajData)})
      .then(resp => {
        console.log(resp)
      })
      .catch()
      .then(() => availabilitySpinner.value = false)
    }

    watch(calendars, () => {
      filterEvents()
    })

    onMounted(()=>{
      getCalendars()
    })

    const eventDataUpdated = (arg) => {
      openedEvent.title = arg.title
      openedEvent.start = arg.start_time
      openedEvent.end   = arg.end_time
      openedEvent.extendedProps.description = arg.description
    }

    const updateEvent = () => {
      const calendarAPI = calendarRef.value.getApi()
      if(!openedEvent.defId.length){
        openedEventObj.obj = calendarAPI.addEvent(openedEvent)
      }
      eventUpdateSpinner.value = true
      axios.post('/api/advisor/calendar/update_event', openedEvent)
      .then( resp => {
        //setModalOpenStatus(false)
        setEventAddEditModalOpenStatus(false)
        toastController.create({
          color: 'success',
          message: 'Thank you! Event successfully updated',
          duration: 3500,
        }).then((toast) => toast.present())
        openedEventObj.obj.setDates(openedEvent.start, openedEvent.end)
        openedEventObj.obj.setExtendedProp('description', openedEvent.extendedProps.description)
        openedEventObj.obj.setExtendedProp('event_id', resp.data.data.event_id)
        openedEventObj.obj.setProp('title', openedEvent.title)
      })
      .catch( (error) => {
        console.log(error)
        if(error.response && error.response.status === 422){ // Validation error.
          toastController.create({
            color: 'danger',
            message: error.response.data.message,
            duration: 3500,
          }).then((toast) => toast.present())
        }
      })
      .then(() => eventUpdateSpinner.value = false)
    }

    const deleteEvent = async () => {

      const loader = await loadingController.create({message: 'Please wait...'})
      const iAlert = await alertController.create({
        header: 'Delete event',
        message: 'Are you sure to delete this event?',
        buttons: [
          'Cancel',
          {
            text: 'Delete',
            handler: () => {
              loader.present()
              axios.post('/api/advisor/calendar/delete_event', {id: openedEvent.extendedProps.event_id, calendar: openedEvent.extendedProps.calendar})
              .then(resp => {
                if(!resp.data.success){
                  openedEventObj.obj.remove()
                  toastController.create({message: resp.data.message, duration:3500, color: "danger", position: "top"}).then((t) => t.present())
                }
                else{
                  openedEventObj.obj.remove()
                  setModalOpenStatus(false)
                  toastController.create({message: 'Event deleted', duration:3500, color: "tertiary", position: "top"}).then((t) => t.present())
                }
              })
              .catch(er=>{
                toastController.create({message: 'Error: '+er.response.status+' '+er.response.statusText, duration:3500, color: "danger", position: "top"}).then((t) => t.present())
              })
              .then(() => loader.dismiss())
            }
          }
        ],
      })
      await iAlert.present()
    }

    return {
      calendarOptions,
      calendarRef,
      deleteEvent,
      isModalOpenRef,
      setModalOpenStatus,
      openedEvent,

      eventAddEditModalOpenRef,
      setEventAddEditModalOpenStatus,

      calendars,
      connectGoogleCalendar,
      googleCalendarStatus,
      availability,
      updateAvailability,
      availabilitySpinner,
      startEndTimeList,

      eventDataUpdated,
      updateEvent,
      eventUpdateSpinner,
    }
  }
});
</script>
