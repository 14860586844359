<template>
  <row class="gy-3 py-1">
    <column default="12">
      <form-group>
        <label class="overline-title" for="event_title">Event Title</label>
        <div class="form-control-wrap">
          <input type="text" class="form-control" id="event_title" v-model="eventData.title"/>
        </div>
      </form-group>
    </column>
    <column default="6">
      <label class="overline-title">Start Date & Time</label>
      <ion-datetime
          v-if="platform === 'mobile'"
          display-timezone="Europe/Amsterdam"
          display-format="DD-MM-YYYY HH:mm"
          picker-format="DD-MM-YYYY HH:mm" class="form-control"
          :value="eventData.start_time"
          v-model="eventData.start_time">

      </ion-datetime>
      <vue-flatpickr v-else v-model="eventData.start_time" id="d_range_f" class="form-control" :config="vueFlatPickerConfig"></vue-flatpickr>
    </column>
    <column default="6">
      <label class="overline-title">End Date & Time</label>
      <ion-datetime
          v-if="platform === 'mobile'"
          display-timezone="Europe/Amsterdam"
          display-format="DD-MM-YYYY HH:mm"
          picker-format="DD-MM-YYYY HH:mm" class="form-control"
          :value="eventData.end_time"
          v-model="eventData.end_time">

      </ion-datetime>
      <vue-flatpickr v-else v-model="eventData.end_time" id="d_range_f" class="form-control" :config="vueFlatPickerConfig"></vue-flatpickr>
    </column>
    <column default="12">
      <form-group>
        <label class="overline-title" for="event_description">Event Description</label>
        <div class="form-control-wrap">
          <textarea class="form-control" id="event_description" v-model="eventData.description"></textarea>
        </div>
      </form-group>
    </column>
  </row>
</template>

<script>
import {Row, Column, FormGroup} from "@core/layouts";
import {reactive, watch} from "vue";
import { IonDatetime } from "@ionic/vue"
import VueFlatpickr from "vue-flatpickr-component"
import 'flatpickr/dist/flatpickr.css';
import useCurrentPlatform from "@/store/currentPlatform"

export default {
  components: {FormGroup, Column, Row, IonDatetime, VueFlatpickr},
  props: {
    title: String,
    startTime: String,
    endTime: String,
    description: String,
  },
  emits: ['eventDataUpdated'],
  setup(props, {emit}){

    const { platform } = useCurrentPlatform()
    let eventData = reactive({
      title: props.title,
      start_time: props.startTime,
      end_time: props.endTime,
      description: props.description,
    })

    const vueFlatPickerConfig = {
      dateFormat: 'Z',
      altFormat: 'd-m-Y h:i K',
      altInput: true,
      enableTime: true,
      minDate: new Date(),
    }

    watch(eventData, (newV) => {
      emit('eventDataUpdated', newV)
    })

    return {
      eventData,
      platform,
      vueFlatPickerConfig,
    }
  }
}
</script>
