<template>
  <row class="gy-3 py-1">
    <column :sm="6" v-if="undefined !== start_time">
      <h6 class="overline-title">Start Time</h6>
      <p id="preview-event-start">{{ renderDate(start_time) }}</p>
    </column>
    <column :sm="6" v-if="undefined !== end_time ">
      <h6 class="overline-title">End Time</h6>
      <p id="preview-event-end">{{ renderDate(end_time) }}</p>
    </column>
    <column default="12" v-if="undefined !== description">
      <h6 class="overline-title">Description</h6>
      <p id="preview-event-description">{{ description }}</p>
    </column>
  </row>
</template>

<script>
import {Row, Column} from "@core/layouts";
export default {
  components: {Column, Row},
  props: {
    start_time: undefined,
    end_time: undefined,
    description: undefined,
  },
  setup(){
    const renderDate = (dd) => {
      try{
        let d = new Date(dd)
        return d.toLocaleString()
      }catch (e){
        return 'Invalid date'
      }
    }
    return {
      renderDate
    }
  }
}
</script>
